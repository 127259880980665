<template>
  <div>

    <div class="all-page-loader"
      v-if="isLoading">
      <div class="page-loader"></div>
    </div>

    <br>
    <br>
    <div class="container">
      <!-- {{locais}} -->

      <div class="columns is-multiline">
        <div class="column is-3" 
          v-for="(local, index) in locais" 
          :key="index">
          <div class="box">

            ID: {{local.idEscola}}
            <br>
            {{local.nome}}
            <br>
            {{local.count}}
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import Request from '@/utils/Request.js';

  export default {

    data() {
      return {
        isLoading: false,
        locais: []
      }
    },

    mounted(){
      this.getEscolas()
    },

    methods: {
      getEscolas(){
        this.isLoading = true;
        const success = (response) => {
          this.locais = response.data.list;
          this.locais.shift();
          // this.locais.map( (item, index) => {
          //   this.getEquipamentoLocal(index, item.idEscola);
          // })
          this.isLoading = false;
        }
        const error = (error) => {
          this.isLoading = false;
          console.log(error);
        }
        const url = Request.mountUrl2('/escolas/');
        Request.get(url)
          .then(success)
          .catch(error);
      },
      getEquipamentoLocal(index, idLocal){
        this.isLoading = true;
        const success = (response) => {
          let equipamentos = response.data.list;
          console.log(equipamentos.length);
          this.locais[index].count = equipamentos.length;
          this.isLoading = false;
        }
        const error = (error) => {
          this.isLoading = false;
          console.log(error);
        }
        const url = Request.mountUrl2('/equipamentos/?idEscola='+idLocal);
        Request.get(url)
          .then(success)
          .catch(error);
      }
    },

  }
</script>

<style scoped>
</style>